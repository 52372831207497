  import { 
    CustomModal, 
    SendIcon, 
    LocalizationProvider, 
    CustomAlert, 
    TextField, 
    TimePicker,
    useFormik, 
    yup, 
    DatePicker,
    Box,  
    Grid, 
    es, 
    AdapterDayjs,
    AdapterDateFns,
    dayjs,
    LoadingButton,
    CustomModalTypes
  } from "@enerbit/base"; 

  import { Dayjs } from "dayjs";
  import { forwardRef, useImperativeHandle, useRef } from "react";
  import { useDispatch, useSelector } from "react-redux";  
  import { createNotification, hasErrorCreate, isLoadingCreate, setResetVars, successCreate } from "../../../../features/notification/notification";
import { toIsoString } from "../../../../helpers/helpersNotification";
  import { DataCreateNotification, PropsNotifications } from "../../../../models/notificationState";
  import { AppDispatch } from "../../../../store/store";
  export interface ImperativeHandle  {
    changeModal: () => void;
};
  const validationSchema = yup.object({
    subject: yup
      .string() 
      .required('Asunto es requerido'),
      body: yup
      .string()
      .required('Mensaje es requerido'),
      time: yup
      .string()
      .nullable(true)
      .required('Tiempo es requerido'),
      date: yup
      .string()
      .nullable(true)
      .required('Fecha es requerido'),
  });
  
  const ModalCreateNotification = forwardRef(({userId}:PropsNotifications, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector(isLoadingCreate);
    const hasError = useSelector(hasErrorCreate);
    const success = useSelector(successCreate); 

    const newUserModal = useRef<CustomModalTypes>(null);
  
    useImperativeHandle(ref, () => ({
      changeModal() {
        if(newUserModal.current)
        {  
          newUserModal.current.changeModal();
        }
      },
    })); 
  
    const initialValues: DataCreateNotification  =  {
      user_id: "",
      subject: "",
      body: "",
      time: null,
      date: null,
      expires_by_utc: null,
    } 

    const formik = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (values: DataCreateNotification) => { 
        const formatDate = dayjs(values.date).format("YYYY-MM-DD") + " " + dayjs(values.time).format("HH:mm:ss");
        values.user_id = userId; 
        values.expires_by_utc = new Date(formatDate).toISOString();
        dispatch(createNotification(values));
      }
    });
  
    return (
      <CustomModal 
        maxWidth="md" 
        ref={newUserModal} 
        onClose={() => {
        dispatch(setResetVars());
        formik.resetForm();
      }}
        dialogContent={
          <>
            <Box sx={{color: "var(--color-primary)", fontSize: "22px"}}>Crear nueva notificación</Box>
            <form onSubmit={formik.handleSubmit}>
              <Box className="TextField-without-border-radius"> 
                <Box sx={{mt: "10px", mb: "10px"}}>Asunto</Box>   
                <TextField
                  name="subject"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth 
                  sx={{ width: "100%"}}
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={formik.touched.subject && Boolean(formik.errors.subject)}
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </Box> 
              <Box className="TextField-without-border-radius"> 
                <Box sx={{mt: "10px", mb: "10px"}}>Mensaje</Box>  
                <TextField
                  name="body"
                  multiline
                  rows={2}
                  maxRows={5}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth 
                  sx={{ width: "100%"}}
                  value={formik.values.body}
                  onChange={formik.handleChange}
                  error={formik.touched.body && Boolean(formik.errors.body)}
                  helperText={formik.touched.body && formik.errors.body}
                />
              </Box> 
              <Grid container spacing={2}>
                <Grid item xs={6}> 
                  <Box sx={{mt: "10px", mb: "10px"}}>Fecha</Box>  
                    <Box className="TextField-without-border-radius Text-select-field"> 
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                    <DatePicker  
                      minDate={dayjs()}
                      value={formik.values.date}
                      onChange={(newValue: Dayjs | null) => { 
                        formik.setFieldValue("date", newValue); 
                      }}
                      inputFormat="yyyy-MM-dd"
                      renderInput={(params:any) => (
                        <TextField  
                          {...params} 
                          error={formik.touched.date && Boolean(formik.errors.date)}
                          helperText={formik.touched.date && formik.errors.date} 
                          sx={{width: "100%"}}
                        />
                      )}
                    /> 
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item xs={6}> 
                  <Box sx={{mt: "10px", mb: "10px"}}>Hora</Box>  
                    <Box className="TextField-without-border-radius Text-select-field">
                    <LocalizationProvider  
                      dateAdapter={AdapterDayjs}
                    >
                      <TimePicker   
                        value={formik.values.time}
                        onChange={(newValue: Dayjs | null) =>  formik.setFieldValue("time", newValue)}
                        renderInput={(params: any) => 
                        <TextField {...params} 
                          error={formik.touched.time && Boolean(formik.errors.time)}
                          helperText={formik.touched.time && formik.errors.time} 
                          sx={{width: "100%"}}
                        />}
                    />
                    </LocalizationProvider>
                  </Box>
                </Grid>  
              </Grid>
              <Box sx={{ mt: "20px"}}>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  type="submit"
                  startIcon={<SendIcon/>}
                  className="Loading-button"
                  sx={{width: "100%"}}
                  loading={loading}
                >
                  <Box style={{fontWeight: "bold", margin: 0}}>Enviar</Box>
                </LoadingButton>
              </Box>
              { hasError && (
                <CustomAlert 
                  onClose={() => dispatch(setResetVars())}
                  sx={{mt:"20px",backgroundColor: "#fee4e2 !important" }}
                  severity="error"
                  text={"Ocurrió un error"}
                />
              )}
              { success && (
                <CustomAlert 
                  onClose={() => dispatch(setResetVars())}
                  sx={{mt:"20px",backgroundColor: "#d1fadf !important" }}
                  severity="success"
                  text={"Notificación creada exitosamente"}
                />
              )}
            </form>
          </>
        }
      />
    );
  })
  
  export default ModalCreateNotification;
  